import React from "react";
import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import Service1 from "../images/service1.svg";
import Service2 from "../images/service2.svg";
import Service3 from "../images/service3.svg";

function ServicesCard() {
  const isMobile = useMediaQuery("(max-width: 789px)");
  return (
    <Stack marginTop={isMobile?"19px":"16vw"}>
      {!isMobile?(
      <>
      <Box gap={2} sx={{ display: "flex", justifyContent: "center" }}>
        <Typography
          sx={{
            color: "#050038",
            fontFamily: "Inter",
            fontSize: "3.8vw",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "3.8vw",
            letterSpacing: "-1px",
          }}
        >
          We provide
        </Typography>
        <Typography
          sx={{
            color: "var(--Primary-Blue, #0057AD)",
            fontFamily: "Inter",
            fontSize: "3.8vw",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "3.8vw",
            letterSpacing: "-1px",
          }}
        >
          end-to-end
        </Typography>
        <Typography
          sx={{
            color: "#050038",
            fontFamily: "Inter",
            fontSize: "3.8vw",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "3.8vw",
            letterSpacing: "-1px",
          }}
        >
          solution for
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography
          sx={{
            color: "#050038",
            fontFamily: "Inter",
            fontSize: "3.8vw",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "3.8vw",
            letterSpacing: "-1px",
            textAlign: "center",
          }}
        >
          group travel across
          <br />
          10+ countries
        </Typography>
      </Box></>):( 
      <>
      <Box gap={2} sx={{ display: "flex", justifyContent: "center" }}>
        <Typography
          sx={{
            color: "#050038",
            fontFamily: "Inter",
            fontSize: "28px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight:"36px",
            letterSpacing: "-1px",
          }}
        >
          We provide
        </Typography>
        <Typography
          sx={{
            color: "var(--Primary-Blue, #0057AD)",
            fontFamily: "Inter",
            fontSize: "28px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight:"36px",
            letterSpacing: "-1px",
          }}
        >
          end-to-end
        </Typography>
        <Typography
          sx={{
            color: "#050038",
            fontFamily: "Inter",
            fontSize:"28px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight:"36px",
            letterSpacing: "-1px",
          }}
        >
          solution for
        </Typography>
        <Typography
          sx={{
            color: "#050038",
            fontFamily: "Inter",
            fontSize:"28px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight:"36px",
            letterSpacing: "-1px",
            textAlign: "center",
          }}
        >
          group travel across
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography
          sx={{
            color: "#050038",
            fontFamily: "Inter",
            fontSize:"28px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "36px",
            letterSpacing: "-1px",
            textAlign: "center",
          }}
        >
          10+ countries
        </Typography>
      </Box></>)}

      <Stack ml={"7.7vw"} mr={"7.3vw"}>
        <Grid container>
          <Grid item xs={isMobile?12:6} mt={"15vw"}>
            
            <Typography
              sx={{
                color: "#050038",
                fontFamily: "Inter",
                fontSize: isMobile?"56px":"3.8vw",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: isMobile?"56px":"3.8vw",
                letterSpacing: "-1px",
              }}
            >
              Book flight {!isMobile&&<br />}
              tickets &<br /> private jets
            </Typography>
            <Typography
              mt={isMobile?"53px":"1.7vw"}
              sx={{
                color: "#58595D",
                fontFamily: "Inter",
                fontSize: isMobile?"24px":"1.6vw",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: isMobile?"34px":"2.25vw",
              }}
            >
              We offer an extensive selection of flight <br />
              options to match your preferences and
              <br /> budget. Our connections with global airline
              <br />
              partners ensure that you have a hassle-free <br />
              and enjoyable flight experience.
            </Typography>
            <ul
              style={{
                mt:'53px',
                color: "#58595D",
                fontFamily: "Inter",
                fontSize: isMobile?"24px":"1.6vw",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: isMobile?"34px":"2.25vw",
              }}
            >
              <li>Exclusive Deals and Discounts</li>
              <li>Easy Booking and Cancellation Process</li>
              <li>24/7 Customer Support</li>
            </ul>
          </Grid>
          <Grid xs={isMobile?12:6} display="flex" justifyContent={isMobile?"center":"right"}>
            <Box
              mt={isMobile?"53px":"16.5vw"}
              sx={{
                justifyContent: "right",
                backgroundImage: `url(${Service1})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "39vw",
                height: "24.5vw",
              }}
            />
          </Grid>
        </Grid>
        {!isMobile?(<><Grid container>
          <Grid item xs={isMobile?12:6} display="flex">
            <Box
              mt={"7.4vw"}
              sx={{
                backgroundImage: `url(${Service2})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "39vw",
                height: "24.5vw",
              }}
            />
          </Grid>
          <Grid item xs={6} mt={"2.8vw"} display="flex" justifyContent="right">
            <Box>
              <Stack>
                <Typography
                  sx={{
                    color: "#050038",
                    fontFamily: "Inter",
                    fontSize: "3.8vw",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "3.8vw",
                    letterSpacing: "-1px",
                  }}
                >
                  Curated 100k+ <br />
                  hotels across the
                  <br /> globes
                </Typography>
                <Typography
                  mt={"1.7vw"}
                  sx={{
                    color: "#58595D",
                    fontFamily: "Inter",
                    fontSize: "1.6vw",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "2.25vw",
                  }}
                >
                  Whether you prefer the cozy corner of a <br />
                  boutique hotel or the grandeur of a luxury <br />
                  resort. We connect you with properties that <br />
                  provide comfort, amenities, and an immersive <br />
                  stay experience."
                </Typography>
                <ul
                  style={{
                    color: "#58595D",
                    fontFamily: "Inter",
                    fontSize: "1.6vw",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "2.25vw",
                  }}
                >
                  <li>Wide Range of Accommodation Options</li>
                  <li>Best Price Guarantee</li>
                  <li>Personalized Recommendations</li>
                </ul>
              </Stack>
            </Box>
          </Grid>
        </Grid>
        </>):(<> 
          <Grid container>
        <Grid item xs={12} mt={"128px"} display="flex" justifyContent="right">
            <Box>
              <Stack>
                <Typography
                  sx={{
                    color: "#050038",
                    fontFamily: "Inter",
                    fontSize: "56px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "56px",
                    letterSpacing: "-1px",
                  }}
                >
                  Curated 100k+ hotels across the globes 
                </Typography>
                <Typography
                  mt={"53px"}
                  sx={{
                    color: "#58595D",
                    fontFamily: "Inter",
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "34px",
                  }}
                >
                  Whether you prefer the cozy corner of a <br />
                  boutique hotel or the grandeur of a luxury <br />
                  resort. We connect you with properties that <br />
                  provide comfort, amenities, and an immersive <br />
                  stay experience."
                </Typography>
                <ul
                  style={{
                    mt:'53px',
                    color: "#58595D",
                    fontFamily: "Inter",
                    fontSize:"24px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "34px",
                  }}
                >
                  <li>Wide Range of Accommodation Options</li>
                  <li>Best Price Guarantee</li>
                  <li>Personalized Recommendations</li>
                </ul>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={isMobile?12:6} display="flex" justifyContent={isMobile?"center":"right"}>
            <Box
              mt={"53px"}
              sx={{
                backgroundImage: `url(${Service2})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "39vw",
                height: "24.5vw",
              }}
            />
          </Grid>
          </Grid>
          </>)}
        <Grid container>
          <Grid item xs={isMobile?12:6}>
            <Typography
              mt={isMobile?"124px":"12.9vw"}
              sx={{
                color: "#050038",
                fontFamily: "Inter",
                fontSize: isMobile?"56px":"3.8vw",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: isMobile?"56px":"3.8vw",
                letterSpacing: "-1px",
              }}
            >
              Get last mile with {!isMobile&&(<br />)} buses, cabs & mini-
              {!isMobile&&(<br />)}
              vans
            </Typography>
            <Typography
              mt={"1.7vw"}
              sx={{
                color: "#58595D",
                fontFamily: "Inter",
                fontSize: isMobile?"24px":"1.6vw",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: isMobile?"34px":"2.25vw",
              }}
            >
              From airport transfers to car rentals, we
              <br /> ensure that you are transported safely
              <br /> and comfortably to your destination."
            </Typography>
            <ul
              style={{
                color: "#58595D",
                fontFamily: "Inter",
                fontSize: isMobile?"24px":"1.6vw",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: isMobile?"34px":"2.25vw",
              }}
            >
              <li>Exclusive Deals and Discounts</li>
              <li>Easy Booking and Cancellation Process</li>
              <li>24/7 Customer Support</li>
            </ul>
          </Grid>
          <Grid xs={isMobile?12:6} display="flex" justifyContent={isMobile?"center":"right"}>
            <Box
              mt={isMobile?"53px":"16.5vw"}
              sx={{
                justifyContent: "right",
                backgroundImage: `url(${Service3})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "39vw",
                height: "24.5vw",
              }}
            />
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
}

export default ServicesCard;
