import Navbar from "./components/navbar";
import FormCard from "./components/formCard";
import OrganizationCard from "./components/organizationCard";
import ServicesCard from "./components/servicesCard";
import Clients from "./components/clients";
import PersonalServices from "./components/personalServices";
import Reviews from "./components/reviews";
import Footer from "./components/footer";
import Popup from "./components/popup";
import { useEffect, useState } from "react";
import "./Styles.css";

function App() {
  const [openDialog, setOpenDialog] = useState(false);
  const openPopup = () => {
    setOpenDialog(true);
  };
  const closePopup = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
   
    const userAgent = window.navigator.userAgent;
    const os = /Windows/.test(userAgent)
      ? "Windows"
      : /Linux/.test(userAgent)
      ? "Linux"
      : /Mac/.test(userAgent)
      ? "Mac"
      : /Android/.test(userAgent)
      ? "Android"
      : /iOS|iPhone|iPad/.test(userAgent)
      ? "iOS"
      : "Unknown";
    const deviceModel = /iPhone|iPad/.test(userAgent)
      ? "iPhone or iPad"
      : /Android/.test(userAgent)
      ? "Android device"
      : "Unknown";
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const resolution = `${screenWidth}x${screenHeight}`;
    localStorage.setItem("operatingSystem", os);
    localStorage.setItem("deviceModel", deviceModel);
    localStorage.setItem("resolution", resolution);
    console.log()
  }, []);
  return (
    <div style={{ margin: "0px", padding: "0px" }}>
      <Navbar />
      <FormCard openPopup={openPopup} />
      <OrganizationCard />
      <ServicesCard />
      <Clients />
      <PersonalServices />
      <Reviews />
      <Footer openPopup={openPopup} />
      <Popup openDialog={openDialog} closePopup={closePopup} />
    </div>
  );
}

export default App;
