import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import ClientCardList from "./clientCardList";

function Clients() {
  return (
    <Stack marginTop={"34.4vw"}>
      <Typography
        sx={{
          display: "flex",
          justifyContent: "center",
          color: "#050038",
          fontFamily: "Inter",
          fontSize: "3.8vw",
          fontStyle: "normal",
          fontWeight: "700",
          lineHeight: "3.8vw",
          letterSpacing: "-1px",
        }}
      >
        we work with wide range of clients
      </Typography>
      <Box gap={2} sx={{ display: "flex", justifyContent: "center" }}>
        <Typography
          sx={{
            color: "#050038",
            fontFamily: "Inter",
            fontSize: "3.8vw",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "3.8vw",
            letterSpacing: "-1px",
          }}
        >
          from
        </Typography>
        <Typography
          sx={{
            color: "var(--Primary-Blue, #0057AD)",
            fontFamily: "Inter",
            fontSize: "3.8vw",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "3.8vw",
            letterSpacing: "-1px",
          }}
        >
          different industries
        </Typography>
      </Box>
      <ClientCardList/>
    </Stack>
  );
}

export default Clients;
