import React from "react";
import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import og1 from "../images/og1.svg";
import og2 from "../images/og2.svg";
import og3 from "../images/og3.svg";
import og4 from "../images/og4.svg";

function OrganizationCard() {
  const isMobile = useMediaQuery("(max-width: 789px)");
  return (
    <Stack marginTop={isMobile?"0px":"14.5vw"}>
      <Box gap={2} sx={{ display: "flex", justifyContent: "center" }}>
        <Typography
          sx={{
            color: "#050038",
            fontFamily: "Inter",
            fontSize: isMobile ? "20px" : "3.8vw",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: isMobile ? "36px" : "3.8vw",
            letterSpacing: "-1px",
          }}
        >
          Trusted by
        </Typography>
        <Typography
          sx={{
            color: "var(--Primary-Blue, #0057AD)",
            fontFamily: "Inter",
            fontSize: isMobile ? "20px" : "3.8vw",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: isMobile ? "36px" : "3.8vw",
            letterSpacing: "-1px",
          }}
        >
          Top Organizations
        </Typography>
      </Box>
      <Box
        sx={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          gap: isMobile?'32px':"6.5vw",
          marginTop: isMobile?'16px':"7.9vw",
        }}
      >
        <img src={og1} alt="" style={{height:isMobile?'38px':'15vw', width:isMobile?'56px':'19vw'}}/>
        <img src={og2} alt="" style={{height:isMobile?'57px':'12.5vw', width:isMobile?'57px':'12.5vw'}}/>
        <img src={og3} alt="" style={{height:isMobile?'20px':'6.8vw', width:isMobile?'53px':'18.4vw'}}/>
        <img src={og4} alt="" style={{height:isMobile?'40px':'12.5vw', width:isMobile?'37px':'11.5vw'}}/>
      </Box>
    </Stack>
  );
}

export default OrganizationCard;
