import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import LogoLarge from "../images/logolarge.svg";
import cross from "../images/cross.svg";
import { useForm, useController, Controller } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

function Popup({ openDialog, closePopup }) {
  const handleFocus = (e) => {
    e.target.style.borderColor = "var(--light-accent-primary, #4277FF)";
    console.log("Focused");
  };

  const handleBlur = (e) => {
    e.target.style.borderColor = "var(--light-basic-strokes, #D4D4D4)";
    console.log("Blured");
  };
  const schema = yup
    .object({
      name: yup.string().required("Full Name is required"),
      Contactnumber: yup.string().required("Contact number is required"),
      Destination: yup.string().required("Destination is required"),
      Nooftravelers: yup.string().required(" No. of trevelers is required"),
    })
    .required();
  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      Contactnumber: "",
      Destination: "",
      Nooftravelers: "",
      services: "Hotels",
      StartDate: dayjs(),
      EndDate: dayjs(),
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { errorOccured, setErrorOccured } = useState(false);
  const { register, control, handleSubmit, formState } = form;
  const { errors } = formState;
  const { field } = useController({ name: "services", control });
  const handleSave = async (formValues) => {
     setIsLoading(true);
     setIsSubmitted(true);
    const formData = new FormData();
    const userEmail = localStorage.getItem("userEmail");
    const os = localStorage.getItem("operatingSystem");
    const deviceModel = localStorage.getItem("deviceModel");
    const resolution = localStorage.getItem("resolution");
    
    formData.append("operatingSystem", os);
    formData.append("deviceModel", deviceModel);
    formData.append("resolution", resolution);
    formData.append("userEmail", userEmail);
    for (const key in formValues) {
      formData.append(key, formValues[key]);
    }
  
    fetch("http://192.168.29.197:8787/ip-geolocation ", {
      method: 'POST',
      body: formData,
    }) 
      .then((response) => response.json())
      .then((data) => {
        setTimeout(() => {
           setIsLoading(false);
           setIsSubmitted(true);
          console.log("Form submitted:", data);
        }, 2000);
      })
      .catch((error) => {
         setIsLoading(false);
         setErrorOccured(true);
        console.error("Error submitting form:", error);
      });
  };
  
  

  const handleSelectChange = (selectedValue) => {
    field.onChange(selectedValue);
  };

  const servicesoptions = [
    { value: "Hotels", label: "Hotels" },
    { value: "Flight tickets", label: "Flight tickets" },
    { value: "Activities", label: "Activities" },
    { value: "Cabs/transfers", label: "Cabs/transfers" },
    { value: "Visas", label: "Visas" },
    { value: "Others", label: "Others" },
  ];
  return (
    <div>
      <Dialog fullScreen open={openDialog}>
        {isSubmitted ? (
          <Box
            sx={{
              background: "var(--light-background-secondary, #E3EBFF)",
              padding: "96px",
              height: "100vw",
            }}
          >
            <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
              <img
                src={LogoLarge}
                alt=""
                style={{
                  height: "110px",
                  width: "110px",
                }}
              />
              <Typography
                sx={{
                  color: "var(--Primary-Blue, #0057AD)",
                  fontFamily: "Inter",
                  fontSize: "60px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "18px",
                  letterSpacing: "-1px",
                  mt: "64px",
                }}
              >
                flyzy
              </Typography>
              {isLoading ? (
                <>
                  <Typography
                    sx={{
                      color: "var(--light-basic-secondary, #575F6E)",
                      fontFamily: "Inter",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "-1px",
                      mt: "64px",
                    }}
                  >
                    there is an average of 9,728 planes carrying 1,270,406
                    passengers in the sky at any given time
                  </Typography>
                  <CircularProgress size={24} />
                </>
              ) : (
                <>
                  {errorOccured ? (
                    <Typography
                      sx={{
                        color: "var(--light-basic-secondary, #575F6E)",
                        fontFamily: "Inter",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "24px",
                        letterSpacing: "-1px",
                        mt: "64px",
                      }}
                    >
                      Oops !! seems like something went wrong with your query
                    </Typography>
                  ) : (
                    <>
                      <Typography
                        sx={{
                          color: "var(--light-basic-primary, #242731)",
                          fontFamily: "Inter",
                          fontSize: "56px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "56px",
                          letterSpacing: "-1px",
                          mt: "64px",
                        }}
                      >
                        Your request is accepted
                      </Typography>
                      <Typography
                        sx={{
                          color: "var(--light-basic-secondary, #575F6E)",
                          fontFamily: "Inter",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "24px",
                          letterSpacing: "-1px",
                          mt: "64px",
                        }}
                      >
                        Our team will evert back within 12 hours
                      </Typography>
                    </>
                  )}
                </>
              )}
            </Stack>
          </Box>
        ) : (
          <Grid container>
            <Grid
              item
              xs={4.59}
              sx={{
                background: "var(--light-background-secondary, #E3EBFF)",
                height: "1050px",
                padding: "96px",
              }}
            >
              
              <img
                src={LogoLarge}
                alt=""
                style={{
                  height: "110px",
                  width: "110px",
                }}
              />
              <Typography
                sx={{
                  color: "var(--Primary-Blue, #0057AD)",
                  fontFamily: "Inter",
                  fontSize: "60px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "18px",
                  letterSpacing: "-1px",
                  mt: "60px",
                }}
              >
                flyzy
              </Typography>
              <Typography
                sx={{
                  color: "var(--light-basic-primary, #242731)",
                  fontFamily: "Inter",
                  fontSize: "28px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "36px",
                  letterSpacing: "-1px",
                  mt: "64px",
                  ml: "36px",
                }}
              >
                Managed 500+ trips in last 60 days
              </Typography>
              <Typography
                sx={{
                  color: "var(--light-basic-secondary, #575F6E)",
                  fontFamily: "Inter",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "-1px",
                  mt: "16px",
                  ml: "36px",
                }}
              >
                It will take a couple of minutes. <br />
                Please be patient
              </Typography>
            </Grid>
            <Grid
              item
              xs={7.41}
              sx={{
                background: "var(--light-background-primary, #FFF)",
                paddingLeft: "96px",
              }}
            >
              <Button
                sx={{
                  backgroundImage: `url(${cross})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  width: "96px",
                  height: "96px",
                  float: "right",
                }}
                onClick={closePopup}
              />
              <Box marginTop={"96px"}>
                <Typography
                  sx={{
                    color: "var(--light-basic-primary, #242731)",
                    fontFamily: "Inter",
                    fontSize: "28px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "36px",
                  }}
                >
                  What are you looking for ?
                </Typography>
                <Typography
                  sx={{
                    color: "var(--light-basic-secondary, #575F6E)",
                    fontFamily: "Inter",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "24px",
                    mt: "16px",
                  }}
                >
                  Our team generally takes 2 to 12 hours to revert back with
                  your
                  <br />
                  requested plans.
                </Typography>
                <Typography
                  sx={{
                    color: "var(--light-basic-primary, #242731)",
                    fontFamily: "Inter",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "24px",
                    mt: "16px",
                  }}
                >
                  Few details about your trip
                </Typography>
              </Box>
              <form
                style={{ marginTop: "32px" }}
                onSubmit={handleSubmit(handleSave)}
                noValidate
              >
                <div>
                  <Typography
                    sx={{
                      color: "var(--light-basic-primary, #242731)",
                      fontFamily: "Inter",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "24px",
                    }}
                  >
                    Full Name
                  </Typography>
                  <input
                  className="custom-input"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                    {...register("name")}
                  />
                  <div style={{ color: "red", marginTop: "14px" }}>
                    {errors.name?.message}
                  </div>
                </div>
                <div style={{ marginTop: "24px" }}>
                  <Typography
                    sx={{
                      color: "var(--light-basic-primary, #242731)",
                      fontFamily: "Inter",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "24px",
                    }}
                  >
                    Contact number (whatsapp)
                  </Typography>
                  <input
                  className="custom-input"
                  onClick={handleFocus}
                    {...register("Contactnumber")}
                  />
                  <div style={{ color: "red" }}>
                    {errors.Contactnumber?.message}
                  </div>
                </div>
                <div style={{ marginTop: "24px" }}>
                  <Typography
                    sx={{
                      color: "var(--light-basic-primary, #242731)",
                      fontFamily: "Inter",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "24px",
                    }}
                  >
                    Destination
                  </Typography>
                  <input
                  className="custom-input"
                  onClick={handleFocus}
                    {...register("Destination")}
                  />
                  <div style={{ color: "red" }}>
                    {errors.Destination?.message}
                  </div>
                </div>
                <div style={{ marginTop: "24px" }}>
                  <Typography
                    sx={{
                      color: "var(--light-basic-primary, #242731)",
                      fontFamily: "Inter",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "24px",
                    }}
                  >
                    No. of travelers
                  </Typography>
                  <input
                  className="custom-input"
                  onClick={handleFocus}
                    {...register("Nooftravelers")}
                  />
                  <div style={{ color: "red" }}>
                    {errors.Nooftravelers?.message}
                  </div>
                </div>
                <div style={{ marginTop: "24px" }}>
                  <Typography
                    sx={{
                      color: "var(--light-basic-primary, #242731)",
                      fontFamily: "Inter",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "24px",
                    }}
                  >
                    kind of services
                  </Typography>
                </div>
                <div>
                  <select
                    value={field.value}
                    onChange={handleSelectChange}
                    style={{
                      width: "330px",
                      padding: "8px",
                      height: "44px",
                      alignItems: "flex-start",
                      color: "var(--light-basic-primary, #242731)",
                      fontFamily: "Inter",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "24px",
                      borderRadius: "6px",
                      border: "1px solid var(--light-basic-strokes, #D4D4D4) ",
                      outline: "none",
                    }}
                    onFocus={(e) => {
                      e.target.style.borderColor =
                        "var(--light-accent-primary, #4277FF)"; // Change the border color when focused
                    }}
                    onBlur={(e) => {
                      e.target.style.borderColor =
                        "var(--light-basic-strokes, #D4D4D4)"; // Reset the border color when blurred
                    }}
                  >
                    {servicesoptions.map(({ value, label }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                </div>

                <Grid container gap={"32px"} mt={"24px"}>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "var(--light-basic-primary, #242731)",
                        fontFamily: "Inter",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "24px",
                      }}
                    >
                      Start date
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Controller
                        name="StartDate"
                        control={control}
                        defaultValue={dayjs()}
                        render={({ field }) => (
                          <DatePicker minDate={dayjs()} {...field} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "var(--light-basic-primary, #242731)",
                        fontFamily: "Inter",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "24px",
                      }}
                    >
                      End date
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Controller
                        name="Enddate"
                        control={control}
                        defaultValue={dayjs()}
                        render={({ field }) => (
                          <DatePicker minDate={dayjs()} {...field} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Grid container mt={"64px"} gap={"8px"}>
                  <Grid item>
                    <Button
                      sx={{
                        height: "48px",
                        padding: "0px 32px",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "8px",
                        borderRadius: "4px",
                        border: "1px solid var(--light-basic-thriary, #79808F)",
                        color: "var(--light-basic-primary, #242731)",
                        fontFamily: "Inter",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "24px",
                      }}
                      onClick={closePopup}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      sx={{
                        height: "48px",
                        padding: "0px 32px",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "8px",
                        borderRadius: "4px",
                        background: "var(--light-background-contrast, #242731)",
                        border: "1px solid var(--light-basic-thriary, #79808F)",
                        color: "#FFFFFF",
                        fontFamily: "Inter",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "24px",
                        "&:hover": {
                          backgroundColor:
                            "var(--light-background-contrast, #242731)",
                        },
                      }}
                      type="submit"
                    >
                      Save changes
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        )}
      </Dialog>
    </div>
  );
}

export default Popup;
