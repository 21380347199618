import React from "react";
import Logo from "../images/logoSmall.svg";
import { Button, Grid, Stack } from "@mui/material";

function navbar() {
  return (
    <Stack>
      <Grid container>
        <Grid item xs={6}>
          <img
            src={Logo}
            alt=""
            style={{
              height: "4.5vw",
              width: "4.5vw",
              marginLeft: "3.5vw",
              marginTop: "1vw",
            }}
          />
        </Grid>
        <Grid
          item
          xs={6}
          display="flex"
          gap={"2.8vw"}
          sx={{
            alignItems: "center",
            justifyContent: "right",
            marginTop: "1.5vw",
          }}
        >
          <Grid
            item
            sx={{
              color: "#050038",
              textAlign: "center",
              fontFamily: "Inter",
              fontSize: "1.3vw",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "1.6vw",
            }}
          >
            Product
          </Grid>
          <Grid
            item
            sx={{
              color: "#050038",
              textAlign: "center",
              fontFamily: "Inter",
              fontSize: "1.3vw",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "1.6vw",
            }}
          >
            Blogs
          </Grid>
          <Grid item>
            <Button
              sx={{
                height: "3.8vw",
                width: "11.8vw",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "24px",
                border: "1px solid #4262FF",
                background:
                  "var(--Flyzy-Grdt, linear-gradient(180deg, #0C7FF2 0%, #0057AD 100%))",
                color: "white",
                marginRight: "4.8vw",
                 fontSize: "1vw"
              }}
            >
              Get in touch →
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default navbar;
