import {
  Box,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

function ClientCardList() {
  const clientData = require("./clients.json");
  return (
    <Box display="flex" ml={"5vw"} mr={"5vw"} >
      <Grid container spacing={"2vw"} marginTop={"10.5vw"}>
        {clientData.map((client) => (
          <Grid item xs={4}>
            <Card
              key={client.id}
              sx={{
                boxShadow: '0px 1px 20px 0px rgba(0, 0, 0, 0.20)',
                height: "33.8vw",
                borderRadius: '13px',
                border: "1px solid var(--secondary-100, #E8E8EA)",
                backgroundColor: `${client.color}`,
              }}
            >
               <Box
              sx={{
               mt:'3.8vw',
               ml:'5.3vw',
                alignContent:'center',
                // backgroundImage: `url(${client1})`,
                backgroundImage: `url(${client.image})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "18.7vw",
                height: "13.7vw",
                
              }}
            />

              <CardContent>
                <Stack margin={"2.8vw"} marginTop={"0.8vw"} width= '20.5vw'>
                  <Typography
                    sx={{
                        color: '#2E2F35',
                        textAlign: 'center',            
                        fontFamily: 'Inter',
                        fontSize: '1.88vw',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: '2.4vw',
                    }}
                  >
                    {client.title}
                  </Typography>
                

                <Stack mt="1.3vw" sx={{ alignItems: "center" ,width: '21vw', justifyContent:'center'}}>
                  <Typography sx={{ color: '#58595D',
                        textAlign: 'center',
                        fontFamily: 'Inter',
                        fontSize: '1.2vw',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '1.6vw',}}>
                    "{client.description}"
                  </Typography>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default ClientCardList;
