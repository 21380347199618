import { Box, Button, Stack, Typography, useMediaQuery } from "@mui/material";
import Rating from "../images/Rating.svg";
import { useState } from "react";

function FormCard({ openPopup }) {
  const isMobile = useMediaQuery("(max-width: 789px)");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [disableButton, setDisableButton] = useState(false);

  const handleSubmit = () => {
    if (email === "") {
      setDisableButton(true);
      setMessage("Please enter an email");
    } else {
      localStorage.setItem("userEmail", email);
      openPopup();
    }
  };

  const handleInputChange = (event) => {
    setDisableButton(false);
    setEmail(event.target.value);
    setMessage("");
  };

  return (
    <Stack marginTop={"6.2vw"}>
      <Box
        gap={isMobile ? 1 : 2}
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: isMobile && "98px",
        }}
      >
        <Typography
          sx={{
            color: "#050038",
            fontFamily: "Inter",
            fontSize: isMobile ? "28px" : "3.8vw",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: isMobile ? "36px" : "3.8vw",
            letterSpacing: "-1px",
          }}
        >
          Curating best
        </Typography>
        <Typography
          sx={{
            color: "var(--Primary-Blue, #0057AD)",
            fontFamily: "Inter",
            fontSize: isMobile ? "28px" : "3.8vw",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: isMobile ? "36px" : "3.8vw",
            letterSpacing: "-1px",
          }}
        >
          group trips
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "0.7vw",
        }}
      >
        {isMobile ? (
          <Typography
            sx={{
              textAlign: "center",
              color: "rgba(5, 0, 56, 0.72)",
              fontFamily: "Inter",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "24px",
            }}
          >
            plan your end to end group trips from
            <br /> 10 to 10,000 people at a single
            <br />
            platform
          </Typography>
        ) : (
          <Typography
            sx={{
              color: "rgba(5, 0, 56, 0.72)",
              fontFamily: "Inter",
              fontSize: "1.2vw",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "1.6vw",
            }}
          >
            plan your end to end group trips from 10 to 10,000 people at a
            single platform
          </Typography>
        )}
      </Box>
      <Box sx={{ justifyContent: "center", display: "flex", mt: "1.9vw" }}>
        <input
          style={{
            borderRadius: isMobile ? "32px" : "2.2vw",
            border: isMobile ? "1px solid #E1E5EA" : "1px solid #000",
            width: isMobile ? "318px" : "27.2vw",
            height: isMobile ? "52px" : "3.2vw",
            alignItems: "center",
            paddingLeft: isMobile ? "18px" : "1.7vw",
            color: isMobile ? "#97A5B5" : "#5F5C80",
            fontFamily: "Inter",
            fontSize: isMobile ? "16px" : "1.2vw",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "1.8vw",
            outline: "none",
          }}
          type="email"
          placeholder={
            isMobile ? "name@yourcompany.com" : "Enter your work email"
          }
          value={email}
          onChange={handleInputChange}
        />
      </Box>
      <Box
        style={{
          color: "red",
          justifyContent: "center",
          display: "flex",
          mt: isMobile ? "8px" : "3vw",
        }}
      >
        {message}
      </Box>

      <Box sx={{ justifyContent: "center", display: "flex", mt: "0.5vw" }}>
        <Button
          sx={{
            height: isMobile ? "52px" : "3.4vw",
            width: isMobile ? "335px" : "29.5vw",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "24px",
            border: "1px solid #4262FF",
            background:
              "var(--Flyzy-Grdt, linear-gradient(180deg, #0C7FF2 0%, #0057AD 100%))",
            color: "#FFF",
            textAlign: "center",
            fontFamily: "Inter",
            fontSize: isMobile? "15px":"1.2vw",
            fontStyle: "normal",
            fontWeight: isMobile?"500":"400",
            lineHeight: "1.8vw",
          }}
          disabled={disableButton}
          onClick={handleSubmit}
        >
         {isMobile?(`Try Free`):( `Start Booking →`)}
        </Button>
      </Box>
      {!isMobile && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1.4vw",
          }}
        >
          <Typography
            sx={{
              color: "rgba(5, 0, 56, 0.40)",
              fontFamily: "Inter",
              fontSize: "1.2vw",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "1.8vw",
            }}
          >
            Book inventory across the globe within minutes
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: "0.6vw",
          backgroundColor:isMobile&& "#F5F5F7",
        }}
      >
        <img style={{ height: isMobile?"56px":"4.4vw", width: !isMobile && "16.2vw" }} src={Rating} alt="" />
      </Box>
    </Stack>
  );
}

export default FormCard;
