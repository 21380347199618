import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import twiter from "../images/twiter.svg";
import facebook from "../images/facebook.svg";
import youtube from "../images/youtube.svg";

function Footer({openPopup}) {
  const [email, setEmail] = useState(""); 
  const [message, setMessage] = useState(""); 
const [disableButton, setDisableButton] = useState(false);

const handleSubmit = () => {
  if (email === "") {
    setDisableButton(true);
    setMessage("Please enter an email"); 
  } else {
    localStorage.setItem("userEmail", email); 
    openPopup();
  } 
};
const handleInputChange = (event) => {
  setDisableButton(false)
  setEmail(event.target.value); 
  setMessage(""); 
};


  return (
    <Stack
      sx={{
        background:
          "var(--Flyzy-Grdt, linear-gradient(180deg, #0C7FF2 0%, #0057AD 100%))",
        display: "flex",

        padding: "6.4vw 12.8vw",
        alignItems: "flex-start",
      }}
    >
      <Box>
        <Typography
          sx={{
            color: " #FFF",
            fontFamily: "Inter",
            fontSize: "1.87vw",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "2.4vw",
          }}
        >
          "Connecting Journeys, Creating Memories – Your Trusted Group Travel
          Partner."
        </Typography>
      </Box>
      <Grid container>
        <Grid item xs={4}>
          <Box gap={"2.7vw"} mt={"5.7vw"} display={"flex"}>
            <img src={twiter} alt="" style={{ width: "2.4vw", height: "2.4vw" }} />
            <img src={facebook} alt="" style={{ width: "2.4vw", height: "2.4vw" }} />
            <img src={youtube} alt="" style={{ width: "2.4vw", height: "2.4vw" }} />
          </Box>
          <Box gap={"1.7vw"} mt={"3.5vw"} display={"flex"}>
            <Typography
              sx={{
                color: " #FFF",
                fontFamily: "Inter",
                fontSize: "1.2vw",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "1.6vw",
              }}
            >
              Privacy Policy
            </Typography>
            <Typography
              sx={{
                color: " #FFF",
                fontFamily: "Inter",
                fontSize: "1.2vw",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "1.6vw",
              }}
            >
              Terms of Service
            </Typography>
          </Box>
          <Typography
            sx={{
              mt: "2.64vw",
              color: " #FFF",
              fontFamily: "Inter",
              fontSize: "1.2vw",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "1.6vw",
            }}
          >
            Copyright © 2022 Certo Software
            <br /> Limited | Registered in England &<br /> Wales No. 10072356
          </Typography>
          <Typography
            sx={{
              mt: "1.2vw",
              color: " #FFF",
              fontFamily: "Inter",
              fontSize: "1.2vw",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "1.6vw",
            }}
          >
            Designed & developed by Bigger Picture
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Box mt={"5.1vw"} borderBottom={"1px solid #FFF"}>
            <Typography
              sx={{
                color: " #FFF",
                fontFamily: "Inter",
                fontSize: "2.35vw",
                fontStyle: "normal",
                fontWeight: "800",
                lineHeight: "3vw",
              }}
            >
              flyzy
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            mt={"5.5vw"}
            borderRadius={"1vw"}
            padding={"2.4vw"}
            sx={{ backgroundColor: " #FFF", width: "29.2vw", float: "right" }}
          >
            <Typography
              sx={{
                color: " #02033B",
                fontFamily: "Inter",
                fontSize: "1.88vw",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "2.4vw",
              }}
            >
              Start booking now
            </Typography>
            <Box sx={{ mt: "1.9vw", display: "flex" }}>
              <input
                style={{
                  borderRadius: " 2.1vw 0px 0px 2.1VW",
                  border: "1px solid #000",
                  height:'3.1vw',
                  width: "17VW",
                  paddingLeft: "0.8VW",
                  alignItems: "center",
                  color: "rgba(2, 3, 59, 0.50)",
                  fontFamily: "Inter",
                  fontSize: "1.2vw",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "1.6VW",
                  outline: "none",
                }}
                type="text"
                placeholder="Enter your work email"
                value={email}
          onChange={handleInputChange}
              />
              <Button
                sx={{
                  padding: "0.8vw 0px",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "0px 2.2vw 2.2vw 0px",
                  backgroundColor: "#02033B",
                  width: "7.4vw",
                  height:'3.37vw',
                  color: "#FFF",
                  fontSize: "1.2vw",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "1.6vw",
                  '&:hover': {
                    backgroundColor: "#02033B",
                  },
                  
                }}
                disabled={disableButton} 
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Box>
            <Box style={{ color: "red"}}>
              <Stack ml={'2vw'}>{message}</Stack></Box>
          </Box>
        </Grid>
      </Grid>
      <Typography
        sx={{
          mt: "8.6vw",
          color: " #FFF",
          fontFamily: "Inter",
          fontSize: "1.2vw",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "1.6vw",
        }}
      >
        Apple, the Apple logo, and iPhone are trademarks of Apple Inc.,
        <br />
        registered in the U.S. and other countries. App Store is a service
        <br /> mark of Apple Inc. Android, Google Play and the Google Play{" "}
        <br />
        logo are trademarks of Google LLC.
      </Typography>
    </Stack>
  );
}

export default Footer;
