import { Box, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import ps1 from "../images/ps1.svg";
import ps2 from "../images/ps2.svg";
import ps3 from "../images/ps3.svg";
import globe from "../images/globe.svg";

function PersonalServices() {
  return (
    <Stack marginTop={"14.7VW"}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography
          sx={{
            color: "#050038",
            fontFamily: "Inter",
            fontSize: "3.8vw",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "3.8vw",
            letterSpacing: "-1px",
          }}
        >
          Other personalized services
        </Typography>
      </Box>
      <Box display="flex" ml={"15.6VW"} mr={"15.6VW"}>
        <Grid
          container
          spacing={"2VW"}
          marginTop={"5.35VW"}
          justifyContent={"center"}
        >
          <Grid item XS={4}>
            <Card
              sx={{
                width: "20.5VW",
                height: " 20.9VW",
                border: "1px solid black",
                borderRadius: "1.7VW",
              }}
            >
              <Box
                sx={{
                  mt: "3.25VW",
                  ml: "7.6VW",
                  backgroundImage: `url(${ps1})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  width: "5.2VW",
                  height: "5.6VW",
                }}
              />
              <CardContent>
                <Stack display="flex" justifyContent={"center"}>
                  <Typography
                    sx={{
                      mt: "1.2vw",
                      color: "var(--text-heading-color, #1E1D4C)",
                      textAlign: "center",
                      fontFamily: "Inter",
                      fontSize: "1.85vw",
                      fontStyle: "normal",
                      fontWeight: "700",
                      lineHeight: "2.4vw",
                    }}
                  >
                    Meet & greet
                  </Typography>

                  <Typography
                    sx={{
                      color: "var(--TEXT-CLR, #5E6282)",
                      textAlign: "center",
                      fontFamily: "Inter",
                      fontSize: "1.6vw",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "2.2vw",
                    }}
                  >
                    Get personalized <br />
                    experience at <br />
                    arrivals
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item XS={4}>
            <Card
              sx={{
                width: "20.5VW",
                height: " 20.9VW",
                border: "1px solid black",
                borderRadius: "1.7VW",
              }}
            >
              <Box
                sx={{
                  mt: "3.25VW",
                  ml: "7.6VW",
                  backgroundImage: `url(${ps2})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  width: "5.2VW",
                  height: "5.6VW",
                }}
              />
              <CardContent>
                <Stack display="flex" justifyContent={"center"}>
                  <Typography
                    sx={{
                      mt: "1.2vw",
                      color: "var(--text-heading-color, #1E1D4C)",
                      textAlign: "center",
                      fontFamily: "Inter",
                      fontSize: "1.85vw",
                      fontStyle: "normal",
                      fontWeight: "700",
                      lineHeight: "2.4vw",
                    }}
                  >
                    Visa services
                  </Typography>

                  <Typography
                    sx={{
                      color: "var(--TEXT-CLR, #5E6282)",
                      textAlign: "center",
                      fontFamily: "Inter",
                      fontSize: "1.6vw",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "2.2vw",
                    }}
                  >
                    Get visa for 100+ <br />
                    destination
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item XS={4}>
            <Card
              sx={{
                width: "20.5VW",
                height: " 20.9VW",
                border: "1px solid black",
                borderRadius: "1.7VW",
              }}
            >
              <Box
                sx={{
                  mt: "3.25VW",
                  ml: "7.6VW",
                  backgroundImage: `url(${ps3})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  width: "5.2VW",
                  height: "5.6VW",
                }}
              />
              <CardContent>
                <Stack display="flex" justifyContent={"center"}>
                  <Typography
                    sx={{
                      mt: "1.2vw",
                      color: "var(--text-heading-color, #1E1D4C)",
                      textAlign: "center",
                      fontFamily: "Inter",
                      fontSize: "1.85vw",
                      fontStyle: "normal",
                      fontWeight: "700",
                      lineHeight: "2.4vw",
                    }}
                  >
                    Special requests
                  </Typography>

                  <Typography
                    sx={{
                      color: "var(--TEXT-CLR, #5E6282)",
                      textAlign: "center",
                      fontFamily: "Inter",
                      fontSize: "1.6vw",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "2.2vw",
                    }}
                  >
                    From birthday cakes,
                    <br /> personalized guide
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          backgroundImage: `url(${globe})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          height: "77vw",
        }}
      />
    </Stack>
  );
}

export default PersonalServices;
