import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

function Reviews() {
  return (
    <Stack marginTop={"2.4vw"}>
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Typography
        sx={{
          color: "#050038",
          fontFamily: "Inter",
          fontSize: "3.7vw",
          fontStyle: "normal",
          fontWeight: "700",
          lineHeight: "3.7vw",
          letterSpacing: "-1px",
        }}
      >
        Our partners share their experience 
      </Typography>
    </Box>
    </Stack>
  );
}

export default Reviews;
